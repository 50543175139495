/**
 * Settings for Axios Http Api calls.
 * Create a copy of this file with name Request.js and enter config there.
 * Request.js file is not tracked in .git
 */


// Base url of backend app
export const APP_BASE_URL = "http://195.35.9.114/";

// Base url of backend api
export const API_BASE_URL = "http://195.35.9.114/api/";

// Base url of frontend app
export const FRONT_BASE_URL = "http://195.35.9.114/";

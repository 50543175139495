import { CButton, CCard, CCardBody, CCol, CForm, CInputGroup, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import BaseListing from 'src/BaseListings'
import WidgetBranchSelector from '../../widgets/Selectors/WidgetBranchSelector'
import WidgetDepartmentSelector from '../../widgets/Selectors/WidgetDepartmentSelector'
import WidgetDesignationSelector from '../../widgets/Selectors/WidgetDesignationSelector'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { lzNotifyErrorMessageFromResult } from '../../helpers/commons/notifications'
import WidgetEmployeeSelector from 'src/widgets/Selectors/WidgetEmployeeSelector'
import DetailedDataReportTable from './DetailedDataReportTable'
import Loading from 'src/helpers/commons/loader'
import { wtGetDateMonthYearFormat } from 'src/helpers/commons/moment'
import { fileStatusOptions } from 'src/helpers/commons/dropdownOptions'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: '100px',
    key: 'id',
    fixed: 'left',
  },
  {
    title: 'Card No',
    dataIndex: 'personal_file_no',
    width: '100px',
    key: 'personal_file_no',
    fixed: 'left',
  },
  {
    title: 'Full Name',
    dataIndex: 'full_name',
    width: '100px',
    key: 'full_name',
    fixed: 'left',
  },
  {
    title: 'Designation',
    width: '100px',
    key: 'designation_id',
    render: (record) => record.designation.name,
  },
  {
    title: 'Department',
    width: '100px',
    key: 'department_id',
    render: (record) => record.department.name,
  },
  {
    title: 'Moblie NO',
    dataIndex: 'mobile_no',
    width: '100px',
    key: 'moblie_no',
  },
  {
    title: 'Phone No',
    dataIndex: 'phone_no',
    width: '100px',
    key: 'phone_no',
  },
  {
    title: 'Permanent Address',
    dataIndex: 'permanent_address',
    width: '100px',
    key: 'permanent_address',
  },
  {
    title: 'Resident Address',
    dataIndex: 'resident_address',
    width: '100px',
    key: 'resident_address',
  },

  {
    title: 'Gender',
    dataIndex: 'gender',
    width: '100px',
    key: 'gender',
  },
  {
    title: 'Grade',
    dataIndex: 'grade',
    width: '100px',
    key: 'grade',
  },
  {
    title: 'Domicile',
    dataIndex: 'domicile',
    width: '100px',
    key: 'domicile',
  },
  {
    title: 'CNIC',
    dataIndex: 'cnic',
    width: '100px',
    key: 'cnic',
  },

  {
    title: 'CNIC Issue Date',
    dataIndex: 'cnic_issue_date',
    width: '100px',
    key: 'cnic_issue_date',
  },
  {
    title: 'CNIC Expiry Date',
    dataIndex: 'cnic_expiry_date',
    width: '100px',
    key: 'cnic_expiry_date',
  },
  {
    title: 'Date Of Birth',
    dataIndex: 'date_of_birth',
    width: '100px',
    key: 'date_of_birth',
  },
  {
    title: 'Date Of Join',
    dataIndex: 'date_of_joining',
    width: '100px',
    key: 'date_of_joining',
    render: (text) => wtGetDateMonthYearFormat(text),
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    width: '100px',
    key: 'duration',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    width: '100px',
    key: 'age',
  },
  {
    title: 'Job Status',
    dataIndex: 'job_status',
    width: '100px',
    key: 'job_status',
  },
  {
    title: 'Personal Email',
    dataIndex: 'personal_email',
    width: '100px',
    key: 'personal_email',
  },
  {
    title: 'Official Email',
    dataIndex: 'official_email',
    width: '100px',
    key: 'official_email',
  },
  {
    title: 'Marital Status',
    dataIndex: 'marital_status',
    width: '100px',
    key: 'marital_status',
  },
  {
    title: 'Blood Group',
    dataIndex: 'blood_group',
    width: '100px',
    key: 'blood_group',
  },
  {
    title: 'Driving License Issue Date',
    dataIndex: 'driving_license_issue_date',
    width: '100px',
    key: 'driving_license_issue_date',
  },
  {
    title: 'Driving License Expiry Date',
    dataIndex: 'driving_license_expiry_date',
    width: '100px',
    key: 'driving_license_expiry_date',
  },
  {
    title: 'Expiry Date',
    dataIndex: 'expiry_date',
    width: '100px',
    key: 'expiry_date',
  },

  {
    title: 'Emergency Contact Name',
    dataIndex: 'emergency_contact_name',
    width: '100px',
    key: 'emergency_contact_name',
  },
  {
    title: 'Relationship With Employee',
    dataIndex: 'relationship_with_employee',
    width: '100px',
    key: 'relationship_with_employee',
  },

  {
    title: 'Emergency phone No',
    dataIndex: 'emergency_phone_no',
    width: '100px',
    key: 'emergency_phone_no',
  },
  {
    title: 'Emergency Resident Address',
    dataIndex: 'emergency_resident_address',
    width: '100px',
    key: 'emergency_resident_address',
  },
  {
    title: 'Emergency Email',
    dataIndex: 'emergency_email',
    width: '100px',
    key: 'emergency_email',
  },
  {
    title: 'Form Status',
    dataIndex: 'file_status',
    width: '100px',
    key: 'file_status',
    render: (text, record) => fileStatusOptions[record.file_status],
  },
  {
    title: 'Evaluation Template',
    dataIndex: 'performance_evaluation_template',
    width: '100px',
    key: 'performance_evaluation_template',
    // render:(text,record)=>(fileStatusOptions[record])
  },
  {
    title: 'Reporting To Name',
    width: '100px',
    key: 'reporting_to_id',
    render: (record) => (record.reporting_to ? record.reporting_to.full_name : '-'),
  },

  {
    title: 'Manual Attendance',
    width: '100px',
    key: 'manual_attendance',
    render: (record) => (record.manual_attendance == 1 ? 'Yes' : 'No'),
  },
  {
    title: 'Exampted From Attendance',
    width: '100px',
    key: 'exempt_from_attendance',
    render: (record) => (record.exempt_from_attendance == 1 ? 'Yes' : 'No'),
  },
  {
    title: 'Attendance Change Limit',
    width: '100px',
    key: 'attendance_change_monthly_limit',
    render: (record) => record.attendance_change_monthly_limit,
  },
  {
    title: 'PL Number',
    width: '100px',
    key: 'pl_number',
    render: (record) => record.pl_number,
  },
]
const defaultCheckedList = [
  'id',
  'personal_file_no',
  'full_name',
  'designation_id',
  'department_id',
]

export default function EmployeeDataReport() {
  const Base = new BaseListing()
  const [isLoading, setIsLoading] = useState(false)
  const [Employees, setEmployees] = useState([])
  const [EmployeeId, setEmployeeId] = useState(0)
  const [EmployeeName, setEmployeeName] = useState('')
  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState(0)
  const [branch_name, setBranchName] = useState('')
  const [departments, setDepartments] = useState([])
  const [department_id, setDepartmentId] = useState(0)
  const [department_name, setDepartmentName] = useState('')
  const [designations, setDesignations] = useState([])
  const [designation_id, setDesignationId] = useState(0)
  const [designation_name, setDesignationName] = useState('')
  const [errors, setErrors] = useState([])
  const [isMore, setIsMore] = useState(false)
  const [reportData, setReportData] = useState([])
  const [selectedFields, setSelectedFields] = useState([])
  const selectedFieldValues = selectedFields.map((option) => option.value)

  const changeValues = (key, val) => {
    if (key === 'branch_id') {
      setBranchId(val.value)
      setBranchName(val.label)
      getDepartments(val.value)
    }
    if (key === 'department_id') {
      setDepartmentId(val.value)
      setDepartmentName(val.label)
    }
    if (key === 'designation_id') {
      setDesignationId(val.value)
      setDesignationName(val.label)
    }
    if (key === 'employee_id') {
      setEmployeeId(val.value)
      setEmployeeName(val.label)
    }
  }

  const getReportData = () => {
    setIsLoading(true)
    wtApiCall(
      'reports/employee_detailed_data_report',
      {
        employee_id: EmployeeId,
        branch_id: branchId,
        department_id: department_id,
        designation_id: designation_id,
      },
      'get',
      (response) => {
        const { data } = response
        setReportData(data)
        setIsLoading(false)
      },
      (error) => {
        lzNotifyErrorMessageFromResult(error)
      },
    )
  }

  useEffect(() => {
    Base.dropdownData(
      'employees,branches,designations,departments',
      {},
      [setEmployees, setBranches, setDesignations, setDepartments],
      1,
    )
    // getData()
  }, [])
  return (
    <>
      <div style={{ padding: '2%' }}>
        <center>
          <h4>Employee Detailed Data Report</h4>
        </center>
        <CRow style={{ width: '100%' }}>
          <CCol md={12}>
            <CCard className="card-dark">
              <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                <br />
                <CForm>
                  <CRow>
                    {/* <CCol md={1}></CCol> */}
                    <CCol md={1}>
                      <CInputGroup>
                        <label style={{ fontSize: '140%', marginTop: '50px' }}>Filters:</label>
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Employee</label>
                        <WidgetEmployeeSelector
                          employees={Employees}
                          displayValue={EmployeeName}
                          value={EmployeeId}
                          placeholder="Select Employee"
                          onChange={(values) => changeValues('employee_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('employees', {}, [setEmployees], 1)
                          }
                        />
                        {errors.employee_id && <ErrorMessage messages={errors.employee_id} />}
                      </CInputGroup>
                    </CCol>

                    <CCol md={3}>
                      <CInputGroup>
                        <label>Department</label>
                        <WidgetDepartmentSelector
                          departments={departments}
                          displayValue={department_name}
                          value={department_id}
                          placeholder="Select Department"
                          onChange={(values) => changeValues('department_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('departments', {}, [setDepartments], 1)
                          }
                        />
                        {errors.department_id && <ErrorMessage messages={errors.department_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <CInputGroup>
                        <label>Designation</label>
                        <WidgetDesignationSelector
                          designations={designations}
                          displayValue={designation_name}
                          value={designation_id}
                          placeholder="Select Designation"
                          onChange={(values) => changeValues('designation_id', values)}
                          reloadDropdownData={() =>
                            Base.dropdownData('designations', {}, [setDesignations], 1)
                          }
                        />
                        {errors.designation_id && <ErrorMessage messages={errors.designation_id} />}
                      </CInputGroup>
                    </CCol>
                    <CCol md={2}>
                      <CButton
                        color="primary"
                        onClick={() => getReportData()}
                        style={{ marginLeft: '1%', marginTop: '8%' }}
                      >
                        Search
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md={1}>
                      <CInputGroup>
                        {/* <label style={{ fontSize: '140%', marginTop: '10px' }}></label> */}
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}>
                      <div className="form-group">
                        {/* <label>Select Fields</label> */}
                        {/*  {props.viewOnly ? (
                      <div className="form-control">
                        {selectedHolidays.map((holiday, index) => (
                          <span key={holiday.value}>
                            {index > 0 ? ', ' : ''}
                            {holiday.label}
                          </span>
                        ))}
                      </div>
                    ) : (

                                                    <Select
                                                        isMulti
                                                        options={multiplefields}
                                                        value={selectedFields}
                                                        onChange={(selectedOptions) => setSelectedFields(selectedOptions)}
                                                    />

                    )}
                    {errors.holiday && <ErrorMessage messages={errors.holiday} />}
                  */}
                      </div>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        {isLoading ? (
          Loading
        ) : (
          <>
            {reportData.length > 0 ? (
              <DetailedDataReportTable
                reportData={reportData}
                isLoading={isLoading}
                columns={columns}
                defaultCheckedList={defaultCheckedList}
              />
            ) : (
              <center style={{ fontSize: '19px', fontWeight: 'bold' }}>No record to show...</center>
            )}
          </>
        )}
      </div>
    </>
  )
}

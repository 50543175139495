import React, { useState } from 'react'
import { Table, Checkbox } from 'antd'

export default function DetailedDataReportTable({ reportData, columns, defaultCheckedList }) {
  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }))

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }))

  const handleSelect = (event) => {
    if (event.target.checked) {
      let checked = []
      options.map((item) => checked.push(item.value))
      setCheckedList(checked)
      console.log(checked)
    } else {
      setCheckedList([])
    }
  }

  console.log(newColumns);

  return (
    <>
      <Checkbox checked={checkedList.length === options.length} onChange={(ev) => handleSelect(ev)}>
        Select All
      </Checkbox>
      <Checkbox.Group
        value={checkedList}
        options={options}
        onChange={(value) => {
          setCheckedList(value)
        }}
      />
      <Table
        columns={newColumns.filter((column) => column.hidden===false)}
        dataSource={reportData}
        style={{
          marginTop: 24,
        }}
        pagination={false}
        scroll={{
          x: 1500,
        }}
        sticky={{
          offsetHeader: 0,
        }}
      />
    </>
  )
}
